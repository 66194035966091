var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticStyle: { "margin-top": "16px" }, attrs: { id: "cardMessageRule" } },
    [
      _c(
        "v-card-title",
        { staticClass: "pl-0 pr-0 pt-0" },
        [
          _c("TableSearch", {
            attrs: { label: "Pesquisar por assunto..." },
            on: { input: _vm.onInputTableMessageRuleSearch },
            model: {
              value: _vm.filter.term,
              callback: function ($$v) {
                _vm.$set(_vm.filter, "term", $$v)
              },
              expression: "filter.term",
            },
          }),
          _c("v-spacer"),
          _c(
            "v-menu",
            {
              attrs: {
                "offset-y": "",
                left: "",
                "close-on-content-click": _vm.closeFilterAutomatically,
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "v-btn",
                        {
                          staticClass: "btn-exportar-enabled ml-2 border-0",
                          class: {
                            " btn-exportar-disabled": _vm.totalRules === 0,
                          },
                          staticStyle: { width: "150px" },
                          attrs: { icon: "", id: "download-btn" },
                          on: { click: _vm.download },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: {
                                "max-height": "1px !important",
                                "max-width": "1px !important",
                                "margin-right": "12px",
                              },
                            },
                            [_vm._v("mdi-download-outline")]
                          ),
                          _vm._v(" Exportar "),
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              class: _vm.isActive
                                ? "secundary-default-active"
                                : "secundary-default",
                              staticStyle: { width: "127px" },
                              attrs: { icon: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.clickButtonFilter()
                                },
                              },
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _c(
                            "v-icon",
                            {
                              staticStyle: {
                                "max-height": "1px",
                                "max-width": "1px",
                                "margin-right": "12px",
                              },
                            },
                            [_vm._v("mdi-filter-outline")]
                          ),
                          _vm._v(
                            " Filtro (" +
                              _vm._s(_vm.totalSelectedFilters) +
                              ") "
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "v-card",
                {
                  attrs: {
                    elevation: "2",
                    outlined: "",
                    shaped: "",
                    tile: "",
                    width: "350",
                  },
                },
                [
                  _c(
                    "v-card-title",
                    {
                      staticStyle: {
                        "font-size": "20px",
                        "font-weight": "bold",
                      },
                    },
                    [_vm._v("Filtro")]
                  ),
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-0 mt-1",
                          attrs: { align: "center" },
                        },
                        [
                          _c("v-autocomplete", {
                            attrs: {
                              items: _vm.filter.cnpjs,
                              label: "Empresa",
                              multiple: "",
                              dense: "",
                              "append-icon": "mdi-chevron-down",
                              outlined: "",
                              clearable: "",
                              "hide-details": "",
                            },
                            on: { change: _vm.checkSelectedFilters },
                            scopedSlots: _vm._u([
                              {
                                key: "selection",
                                fn: function ({ index }) {
                                  return [
                                    index === 0
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "primary--text",
                                            staticStyle: {
                                              "max-height": "48px",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  `${
                                                    _vm.selectedCnpjs.length
                                                  } selecionado${
                                                    _vm.selectedCnpjs.length > 1
                                                      ? "s"
                                                      : ""
                                                  }`
                                                ) +
                                                " "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.selectedCnpjs,
                              callback: function ($$v) {
                                _vm.selectedCnpjs = $$v
                              },
                              expression: "selectedCnpjs",
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass: "material-icons-outlined arrow-down",
                            },
                            [_vm._v(" keyboard_arrow_down ")]
                          ),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-0 mt-1",
                          attrs: { align: "center" },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "text-blue",
                            attrs: {
                              label: "Autor",
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                            },
                            on: { keyup: _vm.checkSelectedFilters },
                            model: {
                              value: _vm.filter.author,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "author", $$v)
                              },
                              expression: "filter.author",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("br"),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-0 mt-1",
                          attrs: { align: "center" },
                        },
                        [
                          _c("v-text-field", {
                            staticClass: "text-blue",
                            attrs: {
                              label: "E-mail",
                              multiple: "",
                              dense: "",
                              outlined: "",
                              "hide-details": "",
                            },
                            on: { keyup: _vm.checkSelectedFilters },
                            model: {
                              value: _vm.filter.email,
                              callback: function ($$v) {
                                _vm.$set(_vm.filter, "email", $$v)
                              },
                              expression: "filter.email",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-0 mt-5",
                          attrs: { align: "center" },
                        },
                        [
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "primary-default mx-0",
                                attrs: {
                                  id: "apply-btn",
                                  block: "",
                                  color: "success",
                                  disabled: _vm.filterEmpty,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.fetch()
                                  },
                                },
                              },
                              [_vm._v(" Aplicar ")]
                            ),
                          ],
                        ],
                        2
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "mx-0 mt-5",
                          attrs: { align: "center" },
                        },
                        [
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "secundary-default mx-0",
                                attrs: {
                                  id: "clear-filter-btn",
                                  block: "",
                                  disabled: _vm.filterEmpty,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clean()
                                  },
                                },
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    staticStyle: {
                                      "max-width": "1px",
                                      "max-height": "1px",
                                      "margin-right": "9px",
                                    },
                                    attrs: { color: "success" },
                                  },
                                  [_vm._v(" mdi-delete-outline ")]
                                ),
                                _vm._v(" Limpar filtro "),
                              ],
                              1
                            ),
                          ],
                        ],
                        2
                      ),
                      _c("br"),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "12" } },
            [
              [
                _c(
                  "div",
                  {
                    staticClass: "text-right",
                    staticStyle: { "margin-right": "12px" },
                  },
                  [
                    _vm.showChips
                      ? _c(
                          "div",
                          _vm._l(_vm.selectedCnpjs, function (cnpj) {
                            return _c(
                              "v-chip",
                              {
                                key: cnpj,
                                staticClass: "ma-1",
                                attrs: { close: "" },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.removeChip(cnpj)
                                  },
                                },
                              },
                              [_vm._v(" " + _vm._s(cnpj) + " ")]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm.filter.author && _vm.showChips
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip(null, _vm.filter.author)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.filter.author) + " ")]
                        )
                      : _vm._e(),
                    _vm.filter.email && _vm.showChips
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "ma-1",
                            attrs: { close: "" },
                            on: {
                              "click:close": function ($event) {
                                return _vm.removeChip(
                                  null,
                                  null,
                                  _vm.filter.email
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(_vm.filter.email) + " ")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          id: "rules-table",
          search: _vm.assuntoModelo,
          headers: _vm.headers,
          items: _vm.rules,
          "server-items-length": _vm.totalRules,
          "items-per-page": _vm.pagination.size,
          "footer-props": {
            "items-per-page-options": [50, 100],
          },
        },
        on: {
          "update:page": _vm.paginate,
          "update:items-per-page": _vm.setTotalPageSize,
        },
        scopedSlots: _vm._u([
          {
            key: "item.term",
            fn: function ({ item }) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [_vm._v(_vm._s(item.term))]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [_c("span", [_vm._v(_vm._s(item.term))])]
                ),
              ]
            },
          },
          {
            key: "item.emails",
            fn: function ({ item }) {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "span",
                                _vm._g(_vm._b({}, "span", attrs, false), on),
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.emails.toString().replace(",", ";")
                                    )
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _c("span", [
                      _vm._v(_vm._s(item.emails.toString().replace(",", ";"))),
                    ]),
                  ]
                ),
              ]
            },
          },
          {
            key: "item.actions",
            fn: function ({ item }) {
              return [
                _c(
                  "AGTooltip",
                  { attrs: { "tooltip-text": "Editar" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", title: "Editar", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.editRule({ item })
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-pencil"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "AGTooltip",
                  { attrs: { "tooltip-text": "Excluir" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", title: "Excluir", color: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteRule({ item })
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { small: "" } }, [
                          _vm._v("mdi-delete"),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
        ]),
      }),
      _c("ModalNewMessageRule", { ref: "modalNewMessageRule" }),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500" },
          model: {
            value: _vm.show,
            callback: function ($$v) {
              _vm.show = $$v
            },
            expression: "show",
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticStyle: { "font-size": "20px", "font-weight": "bold" } },
                [_vm._v(" Excluir regra "), _c("v-spacer")],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  " Deseja realmente excluir esta regra de notificação automática? "
                ),
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "cancel-btn", text: "", color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.show = false
                        },
                      },
                    },
                    [_vm._v(" Cancelar")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { id: "delete-btn", color: "success" },
                      on: { click: _vm.confirmDeletion },
                    },
                    [_vm._v(" Sim, desejo excluir ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }