<template>
  <v-card style="margin-top: 16px" id="cardMessageRule">
    <v-card-title class="pl-0 pr-0 pt-0">
      <TableSearch
            label="Pesquisar por assunto..."
            v-model="filter.term"
            @input="onInputTableMessageRuleSearch"
          ></TableSearch>
      <v-spacer></v-spacer>
      <v-menu offset-y left :close-on-content-click="closeFilterAutomatically">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            style="width: 150px"
            icon
            v-bind:class="{ ' btn-exportar-disabled': totalRules === 0 }"
            @click="download"
            class="btn-exportar-enabled ml-2 border-0"
            id="download-btn"
          >
            <v-icon style="max-height: 1px !important; max-width: 1px !important; margin-right: 12px"
              >mdi-download-outline</v-icon
            >
            Exportar
          </v-btn>
          <v-btn
            :class="isActive ? 'secundary-default-active' : 'secundary-default'"
            style="width: 127px"
            icon
            v-bind="attrs"
            v-on="on"
            @click="clickButtonFilter()"
          >
            <v-icon style="max-height: 1px; max-width: 1px; margin-right: 12px">mdi-filter-outline</v-icon> Filtro ({{
              totalSelectedFilters
            }})
          </v-btn>
        </template>
        <v-card elevation="2" outlined shaped tile width="350">
          <v-card-title style="font-size: 20px; font-weight: bold">Filtro</v-card-title>
          <v-card-text>
            <v-row align="center" class="mx-0 mt-1">
              <v-autocomplete
                v-model="selectedCnpjs"
                :items="filter.cnpjs"
                label="Empresa"
                multiple
                dense
                append-icon="mdi-chevron-down"
                outlined
                clearable
                hide-details
                @change="checkSelectedFilters"
              >
                <template v-slot:selection="{ index }">
                  <span v-if="index === 0" style="max-height: 48px" class="primary--text">
                    {{ `${selectedCnpjs.length} selecionado${selectedCnpjs.length > 1 ? 's' : ''}` }}
                  </span>
                </template>
              </v-autocomplete>
              <span class="material-icons-outlined arrow-down"> keyboard_arrow_down </span>
            </v-row>
            <br />
            <v-row align="center" class="mx-0 mt-1">
              <v-text-field
                v-model="filter.author"
                label="Autor"
                dense
                class="text-blue"
                outlined
                hide-details
                @keyup="checkSelectedFilters"
              >
              </v-text-field>
            </v-row>
            <br />
            <v-row align="center" class="mx-0 mt-1">
              <v-text-field
                v-model="filter.email"
                label="E-mail"
                class="text-blue"
                multiple
                dense
                outlined
                hide-details
                @keyup="checkSelectedFilters"
              >
              </v-text-field>
            </v-row>
            <v-row align="center" class="mx-0 mt-5">
              <template>
                <v-btn
                  id="apply-btn"
                  block
                  class="primary-default mx-0"
                  color="success"
                  @click="fetch()"
                  :disabled="filterEmpty"
                >
                  Aplicar
                </v-btn>
              </template>
            </v-row>
            <v-row align="center" class="mx-0 mt-5">
              <template>
                <v-btn
                  id="clear-filter-btn"
                  block
                  class="secundary-default mx-0"
                  @click="clean()"
                  :disabled="filterEmpty"
                >
                  <v-icon color="success" style="max-width: 1px; max-height: 1px; margin-right: 9px">
                    mdi-delete-outline
                  </v-icon>
                  Limpar filtro
                </v-btn>
              </template>
            </v-row>
            <br />
          </v-card-text>
        </v-card>
      </v-menu>
    </v-card-title>

    <v-row>
      <v-col cols="12" sm="12">
        <template>
          <div class="text-right" style="margin-right: 12px">
            <div v-if="showChips">
              <v-chip v-for="cnpj in selectedCnpjs" class="ma-1" :key="cnpj" close @click:close="removeChip(cnpj)">
                {{ cnpj }}
              </v-chip>
            </div>
            <v-chip v-if="filter.author && showChips" class="ma-1" close @click:close="removeChip(null, filter.author)">
              {{ filter.author }}
            </v-chip>
            <v-chip
              v-if="filter.email && showChips"
              class="ma-1"
              close
              @click:close="removeChip(null, null, filter.email)"
            >
              {{ filter.email }}
            </v-chip>
          </div>
        </template>
      </v-col>
    </v-row>

    <v-data-table
      id="rules-table"
      :search="assuntoModelo"
      :headers="headers"
      :items="rules"
      :server-items-length="totalRules"
      :items-per-page="pagination.size"
      @update:page="paginate"
      @update:items-per-page="setTotalPageSize"
      :footer-props="{
        'items-per-page-options': [50, 100],
      }"
    >
      <template v-slot:item.term="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.term }}</span>
          </template>
          <span>{{ item.term }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.emails="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span v-bind="attrs" v-on="on">{{ item.emails.toString().replace(',', ';') }}</span>
          </template>
          <span>{{ item.emails.toString().replace(',', ';') }}</span>
        </v-tooltip>
      </template>
      <template v-slot:item.actions="{ item }">
        <AGTooltip tooltip-text="Editar">
          <v-btn icon title="Editar" color="primary" @click="editRule({ item })">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </AGTooltip>
        <AGTooltip tooltip-text="Excluir">
          <v-btn icon title="Excluir" color="primary" @click="deleteRule({ item })">
            <v-icon small>mdi-delete</v-icon>
          </v-btn>
        </AGTooltip>
      </template>
    </v-data-table>
    <ModalNewMessageRule ref="modalNewMessageRule" />
    <v-dialog v-model="show" max-width="500">
      <v-card>
        <v-card-title style="font-size: 20px; font-weight: bold"> Excluir regra <v-spacer></v-spacer> </v-card-title>
        <v-card-text> Deseja realmente excluir esta regra de notificação automática? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn id="cancel-btn" text color="primary" @click="show = false"> Cancelar</v-btn>
          <v-btn id="delete-btn" color="success" @click="confirmDeletion"> Sim, desejo excluir </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import { eventBus } from '../../../../main';
import IntegraContadorService from '@/services/integraContadorService.js';
import AdminServices from '@/services/adminServices.js';
import { mapGetters } from 'vuex';

export default {
  components: {
    ModalNewMessageRule: () => import('./ModalNewMessageRule.vue'),
    TableSearch: () => import('../../commons/TableSearch.vue'),
    AGTooltip: () => import('../../commons/AGTooltip.vue'),
  },

  data() {
    return {
      closeFilterAutomatically: false,
      show: false,
      isActive: false,
      ruleToDelete: null,
      currentUser: null,
      rules: [],
      totalRules: 0,
      assuntoModelo: '',
      selectedCnpjs: [],
      totalSelectedFilters: 0,
      showChips: false,
      filterEmpty: true,
      filter: {
        term: '',
        cnpjs: [],
        author: '',
        email: '',
      },
      params: {
        term: '',
        cnpjs: [],
        authors: [],
        emails: [],
      },
      headers: [
        { text: 'Data', value: 'ruleDate', width: '172px', sortable: false },
        { text: 'Autor', value: 'author', width: '123px', sortable: false },
        { text: 'Empresa', value: 'establishmentName', width: '134px', sortable: false },
        { text: 'Assunto', value: 'term', width: '284px', sortable: false },
        { text: 'Destinatário', value: 'emails', width: '106px', sortable: false },
        { text: 'Ações', value: 'actions', class: 'actions', width: '156px', sortable: false },
      ],
      firstPage: true,
      pagination: {
        page: 0,
        size: 50,
      },
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
    }),
  },
  beforeMount() {
    this.prepareListeners();
  },
  mounted() {
    this.getEstablishments();
    this.showChips = false;
    this.fetch();
  },
  methods: {
    onInputTableMessageRuleSearch() {
      this.fetch();
    },
    clickButtonFilter() {
      this.closeFilterAutomatically = false;
      this.isActive = true;
    },
    async getEstablishments() {
      try {
        let response = await AdminServices.userEstablishment.get(this.userInfo.id);
        let items = response.data;
        items.forEach((item) => {
          this.filter.cnpjs.push(item.establishment.details.cnpj + ' / ' + item.establishment.details.socialName);
        });
      } catch (e) {
        console.error(e);
      }
    },
    async fetch() {
      this.checkSelectedFilters();
      this.showChips = !this.filterEmpty;
      this.closeFilterAutomatically = true;
      this.isActive = false;
      try {
        if (this.filter.author) {
          this.params.authors.push(this.filter.author);
        }
        if (this.filter.email) {
          this.params.emails.push(this.filter.email);
        }
        this.params.term = this.filter.term;
        this.params.cnpjs = Object.assign([], this.selectedCnpjs);

        for (let index = 0; index < this.params.cnpjs.length; index++) {
          this.params.cnpjs[index] = this.params.cnpjs[index].split('/')[0].trim();
        }
        let response = await IntegraContadorService.rule.getAllFilter(this.pagination, this.params);
        this.rules = response.data.content;
        this.totalRules = response.data.totalElements;

        this.rules.forEach((rule) => {
          rule.ruleDate = this.formatRuleDate(rule.createdAt);
        });
        this.params.emails = [];
        this.params.authors = [];
      } catch (e) {
        console.error(e);
      }
    },
    formatRuleDate(date) {
      if (!date) return null;
      if (date.includes('T')) {
        date = date.replace(/T.*/, '');
      }
      const [year, month, day] = date.split('-');
      return `${day}/${month}/${year}`;
    },
    paginate(currentPage) {
      this.pagination.page = currentPage - 1;
      this.fetch();
    },
    setTotalPageSize(totalPageSize) {
      this.pagination.size = totalPageSize;
      this.fetch();
    },
    editRule(index) {
      this.$refs.modalNewMessageRule.$emit('upsert', index.item);
    },
    deleteRule(index) {
      this.ruleToDelete = index.item.id;
      this.show = true;
    },
    async confirmDeletion() {
      try {
        await IntegraContadorService.rule.delete(this.ruleToDelete);
        this.show = false;
        this.showToast('Regra de notificação automática excluida com sucesso!', 'success', 78);
        this.fetch();
      } catch (e) {
        console.error(e);
      }
    },
    clean() {
      this.showChips = false;
      this.filter.term = '';
      this.filter.email = '';
      this.filter.author = '';
      this.selectedCnpjs = this.params.cnpjs = [];

      this.$emit('refresh');
      this.fetch();
    },
    showToast(message, type, topToast) {
      let toast = {
        show: true,
        type: type,
        message: message,
        topToast: topToast,
      };
      eventBus.$emit('openToast', toast);
    },
    async download() {
      let nothingToExport = this.totalRules == 0;
      if (nothingToExport) return;

      let response = await IntegraContadorService.rule.downloadReport(this.pagination, this.params);
      if (response) this.loading3 = false;
      let blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      let link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      let date = new Date();

      link.download = `regras_de_notificao.xlsx`;
      link.click();
      this.showToast('Download iniciado. Verifique o arquivo em seu computador.', 'success', 78);
    },
    async prepareListeners() {
      this.$root.$on('refreshRules', () => {
        this.fetch();
      });
    },
    async removeChip(cnpjToRemove, authorToRemove, emailToRemove) {
      if (cnpjToRemove) {
        let selectedCnpjs = [];
        this.selectedCnpjs.forEach((cnpj) => {
          if (cnpj != cnpjToRemove) {
            selectedCnpjs.push(cnpj);
          }
        });
        this.selectedCnpjs = selectedCnpjs;
      }
      if (authorToRemove) {
        this.filter.author = '';
      }
      if (emailToRemove) {
        this.filter.email = '';
      }
      await this.fetch();
    },
    checkSelectedFilters() {
      this.filterEmpty = !(this.selectedCnpjs.length > 0 || this.filter.author || this.filter.email);
      this.totalSelectedFilters = 0;

      if (this.selectedCnpjs.length > 0) {
        this.totalSelectedFilters++;
      }
      if (this.filter.author) {
        this.totalSelectedFilters++;
      }
      if (this.filter.email) {
        this.totalSelectedFilters++;
      }
    },
  },
};
</script>
<style scoped>
::v-deep .v-data-table-header {
  text-align: start;
  background-color: #e7e7fa;
}

::v-deep .v-data-table-header tr th {
  line-height: 18.62px;
  letter-spacing: 1%;
  height: 51px;
  flex-direction: row;
  align-items: flex-start;
  margin-right: 1px;
}

::v-deep .v-data-table-header tr th.text-start span {
  color: var(--v-primary-base);
  font-family: 'Roboto';
  font-weight: 700;
  font-size: 14px;
}

.secundary-default {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  background-color: #fff !important;
  color: var(--v-primary-base);
}

.secundary-default :hover {
  background: #e3ffee;
  border-radius: 3px;
  width: 143% !important;
  height: 226%;
}

.secundary-default-active {
  height: 48px !important;
  width: 130px !important;
  top: 0px;
  border-radius: 4px !important;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid primary;
  color: #fff !important;
  backgound-color: var(--v-primary-base) !important;
}

.secundary-default-active :hover {
  background: #e3ffee;
  border-radius: 3px;
  width: 143%;
  height: 226%;
  color: var(--v-primary-base);
}

.btn-exportar-enabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  border: 1px solid #fff !important;
  background-color: #fff !important;
  color: var(--v-primary-base);
}
::v-deep.text-blue input {
  color: var(--v-primary-base);
}
.btn-exportar-disabled {
  height: 48px !important;
  top: 0px;
  padding: 12px 16px 12px 16px !important;

  /* Typography  */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.01em;
  text-align: left;
  background-color: #e7e7fa !important;
  color: #8d8d99 !important;
}
::v-deep .v-label {
  color: var(--v-primary-base);
}

::v-deep .v-label--active {
  color: #575767 !important;
}

::v-deep .mdi-calendar {
  color: var(--v-primary-base);
}

::v-deep .mdi-menu-down {
  display: none;
}

.arrow-down {
  color: var(--v-primary-base);
  position: fixed;
  margin-left: 80%;
}

#download-btn :hover {
  background: #e3ffee;
  border-radius: 3px;
  width: 128%;
  height: 230%;
}

#clear-filter-btn :hover {
  width: 115% !important;
  height: 233% !important;
}

#apply-btn :hover {
  background: #e3ffee;
  border-radius: 3px;
  width: 112%;
  height: 298% !important;
  color: var(--v-primary-base);
}

#cancel-btn :hover {
  background-color: #e3ffee !important;
  padding: 15% 25%;
  max-height: 90px;
  border-radius: 5px;
}

#delete-btn :hover {
  background-color: #e3ffee !important;
  padding: 8% 12%;
  border-radius: 5px;
  color: var(--v-primary-base);
}
</style>
